<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="box container">
		<div class="merchant">
			<h3 class="title">{{ product[0].ShopName ? product[0].ShopName : '暂无商家名称' }}</h3>
			<!-- <div class="info">
				<div class="infoimg"><img src="" alt="" class="img"></div>
				
				<div class="detail">爱茉诗（AMOS)韩国顶级美发品牌（AMOS爱茉诗）由韩国AMOS爱茉诗爱茉莉太平洋化妆品集团推出的顶级、高端美发品牌,AMOS爱茉诗世界有十大化妆品集团，亚洲有占三个（太平洋/LG/资生堂）。其中韩国太平洋集团更是亚洲最大的化妆品集团。太平洋是韩国化妆品界最大的化妆品公司，在欧洲国家也是很有影响力的公司，它的市场占有率也是很高的。爱茉莉太平洋株式会社始于1932年，爱茉莉太平洋公司至今已有68年历史。
一直到今天爱茉莉太平洋公司推出了很多顶级、高端护肤品牌。爱茉莉太平洋公司是研发、生产、销售为一体的大型企业。在中国他创有兰芝，爱丽，雪花秀等品牌。
</div>
			</div> -->
			<div class="main">
				<!-- <div class="product">
					<div class="imgbox">
						<img src="" alt="" class="img">
					</div>
					<div class="name">产品名称</div>
					<div class="price">￥49</div>
				</div> -->
				<div class="list" style="width: 1000px;margin:0 auto">
					<MyList :list="product" v-if="product.length > 0"></MyList>
					<div v-else class="none-product">抱歉没有找到相关的商品，请看看其他的商品</div>
				</div>
				<!-- 分页 -->
				<div class="pagination" v-if="product.length > 0" style="display: flex;justify-content:center;">
					<el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total"
						@current-change="currentChange"></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			orders: [], // 订单列表
			ShopId: null,
			product: "",
			total: 0, // 商品总量
			pageSize: 12, // 每页显示的商品数量
			currentPage: 1, //当前页码
			search: '',
		};
	},
	activated() {
		if (this.$route.query.Id != undefined) {
			this.ShopId = this.$route.query.Id;
			this.goodlist()
		}
	},
	methods: {
		async goodlist() {
			const res = await this.postdata('/Goods/GoodsList', {
				page: this.currentPage,
				limit: this.pageSize,
				ShopId: this.ShopId,
				queryName: this.search
			})
			if (res.code == 200) {
				this.product = res.data.items
				this.product.map(v => {
					v.num = 1
					v.tempIndex = 0
				})
				this.total = res.data.total
			}
		},
		backtop() {
			const timer = setInterval(function () {
				const top = document.documentElement.scrollTop || document.body.scrollTop;
				const speed = Math.floor(-top / 5);
				document.documentElement.scrollTop = document.body.scrollTop =
					top + speed;

				if (top === 0) {
					clearInterval(timer);
				}
			}, 20);
		},
		currentChange(currentPage) {
			this.currentPage = currentPage;
			this.getData();
			this.backtop();
		},
		async getData() {
			// 如果分类列表为空则请求全部商品数据，否则请求分类商品数据
			const res = await this.postdata('/Goods/GoodsList', {
				page: this.currentPage,
				limit: this.pageSize,
				ShopId: this.ShopId,
				queryName: this.search
			})
			if (res.code == 200) {
				this.product = res.data.items
				this.total = res.data.total
			}
		}

	},

};
</script>
<style scoped>
#app .el-main {
	min-height: 100vh;
}

.container {
	width: 1226px;
	margin-right: auto;
	margin-left: auto;
}

.container:after,
.container:before {
	content: " ";
	display: table;
}

.merchant {
	background-color: #fff;
	border: 1px solid #eee;
	padding-bottom: 20px;
}

.merchant .title {
	height: 56px;
	line-height: 56px;
	font-size: 18px;
	margin: 0 12px;
	border-bottom: 1px solid #eee;
	margin-bottom: 18px;
}

.merchant .info {
	display: flex;

}

.merchant .infoimg {
	overflow: hidden;
	width: 200px;
	border: 1px solid #eee;
	padding: 5px 10px;
	flex-shrink: 0;
}

.merchant .info .img {

	width: 200px;
}

.merchant .info .detail {
	padding: 5px 10px;
	border: 1px solid #eee;
	border-left: none;
	font-size: 16px;
	line-height: 30px;
}

.merchant .list {
	display: flex;
	margin: 0 12px;
}

.merchant .list .product {
	margin: 0 10px 16px 0;
	width: 200px;
}

.merchant .list .product .imgbox {
	width: 200px;
	height: 200px;
	overflow: hidden;
}

.merchant .list .product .imgbox .img {
	width: 200px;
}

.merchant .list .product .name {
	font-size: 16px;
	line-height: 24px;
	color: #000;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	word-break: break-all;
}

.merchant .list .product .price {
	font-size: 16px;
	color: #ff0000;
}</style>
